//
// Forms
//


// Form group
.form-group {
  margin-bottom: 1rem;

  &.form-group-marginless,
  &.form-group-last {
    margin-bottom: 0px;
  }

  label {
    font-size: 1rem;
    font-weight: 400;
  }

  .invalid-feedback,
  .valid-feedback {
    font-weight: 400;
  }

  .form-text {
    font-size: 0.9rem;
  }

  // Space sizing
  &.form-group-md {
    margin-bottom: 1rem;
  }

  &.form-group-sm {
    margin-bottom: 0.5rem;
  }

  &.form-group-xs {
    margin-bottom: 0.25rem;
  }

  &.form-group-last {
    margin-bottom: 0rem;
  }
}

// Form control
.form-control {
  &:focus,
  &:active {
    box-shadow:none !important;
  }

  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Pill style
  &.form-control-pill {
    border-radius: 50px;
  }
}

// Custom inputs
.custom-select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-file {
  width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none !important;
}

.custom-file-label {
  text-align: left;

  &:after {
      float: left;
  }
}


// Input Group
.input-group {
  @include kt-icons-size((lineawesome: 1.4rem, fontawesome: 1.2rem, flaticon: 1.2rem));

  i {
    color: $input-group-icon-color;
    line-height: 0;
  }

  .form-control.is-valid + .input-group-append,
  .form-control.is-invalid + .input-group-append {
    margin-left: 0;
  }

  .input-group-prepend + .form-control.is-valid,
  .input-group-prepend + .form-control.is-invalid {
    margin-left: 1px;
  }
}

// Validation
.validated {
  .valid-feedback,
  .invalid-feedback {
    display: block;
  }
}

// Custom fields
.select2-container--open {
  z-index: 10000;
}

.custom-field {
  position: relative;

  &-group {
    display: flex;

    .custom-field-wrapper {
      position: relative;
      z-index: 10;
      flex-grow: 1;
    }

    &-append {
      z-index: 10;
      margin-left: -1px;

      &.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .btn {
      box-shadow: none;

      &.disabled:hover {
        box-shadow: none;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      padding: .4rem 1rem;
      font-size: 1.1rem;

      &.bg-light {
        border: 1px solid $input-border-color;
      }
    }
  }

  .custom-label {
    position: absolute;
    left: 16px;
    top: 13px;
    z-index: 1;
    width: calc(100% - 28px);
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 16px;
    line-height: 1;
    color: $input-placeholder-color;
    transform-origin: left top;
    transition: transform .15s cubic-bezier(.4,0,.2,1),
                color .15s cubic-bezier(.4,0,.2,1);
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: text;
    overflow: hidden;
    will-change: transform;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  .custom-input {
    padding: 22px 16px 6px;
    font-size: 16px;
    border-radius: 0;

    &:focus,
    &.has-value {
      & ~ .custom-label {
        width: 100%;
        transform: translateY(-32%) scale(.70);
      }
    }

    &:disabled {
      cursor: not-allowed;

      & ~ .custom-label {
        cursor: not-allowed;
      }
    }
  }

  textarea.custom-input {
    height: auto;
    min-height: 100px;

    & + .custom-label {
      top: 10px;
      background-color: #ffffff;
    }
  }
}

.custom-select2 {
  position: relative;

  .select2-container .select2-selection--single {
    height: 54px !important;
    border-color: $input-border-color !important;
    border-radius: 0;

    .select2-selection__rendered {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 52px !important;
      color: $input-color;
    }

    .select2-selection__arrow {
      height: 52px;
    }
  }

  .select2-container--open .select2-selection--single {
    border-color: $primary !important;
  }

  .select2-container--disabled .select2-selection--single .select2-selection__rendered {
    color: $input-placeholder-color;
  }
}

.custom-select2__label {

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-top: 22px;
    padding-bottom: 6px;
    padding-left: 16px !important;
    line-height: 24px !important;
  }

  .custom-label {
    position: absolute;
    left: 16px;
    top: 4px;
    z-index: 10000;
    width: calc(100% - 28px);
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 11px;
    line-height: 1;
    color: $input-placeholder-color;
  }
}

.input-group-prepend .custom-select2 .select2-container .select2-selection--single {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.input-group-append + .form-control {
  margin-left: -1px;
}
