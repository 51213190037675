//
// Modal
//




// Base
.modal {
  .modal-content {
    @include kt-rounded {
      border-radius: $kt-border-radius;
    }

    .modal-header {
      .modal-title {
        font-weight: 500;
        font-size: 1.3rem;
        color: kt-base-color(label, 4);
      
        small {
          font-weight: 400;
          font-size: 0.9rem;
          color: kt-base-color(label, 2);
        }
      }

      .close {
        outline:none !important;
        color: kt-base-color(label, 2);
      
        @include kt-close-icon();
        @include kt-hover-transition();
      
        &:before {
          font-size: 1.3rem;
        }
      
        span {
          display: none;
        }
      
        &:hover {
          color: kt-brand-color();
        }
      }
    }
  }

  // Stick to bottom right
  &.modal-sticky-bottom-right {
    padding: 0 !important;
    max-width: 500px;
    height: auto;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 25px;
    right: 25px;
    margin: 0;
    box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }
    
    @include kt-tablet-and-mobile {
      bottom: 10px;
      right: 10px;
    }
    
    @include kt-mobile {
      max-width: 90%;
    }
    
    .modal-dialog {
      position: static;
      max-width: 500px;
      width: auto;
      margin: 0;

      @include kt-rounded {
        border-radius: $kt-border-radius;
      }
      
      .modal-content {
        border: 0;
        @include kt-rounded {
          border-radius: $kt-border-radius;
        }
      }
    }
  }
}

.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

.modal-body {
  &.modal-body-fit {
    padding: 0;
  }
  &.modal-body-x-fit {
    padding-left: 0;
    padding-right: 0;
  }
  &.modal-body-y-fit {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.custom-scrollbar {
    max-height: calc(100vh - 200px);
  }
}

@include kt-media-above(xl) {
  .modal-dialog.modal-xl {
    max-width: kt-media-breakpoint(xl) - 100px;
  }
}

// Multiple modal opens
.modal-backdrop + .modal-backdrop {
  z-index: 1055;
}

.modal.show ~ .modal.show {
  z-index: 1060;
}