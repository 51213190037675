//
// Demo1 Stylesheets
//




////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Include global config
@import "../../config";

// 2: Include demo config (that overrides the above global config)
@import "config";

// 3: Bootstrap Include
@import "../../../bootstrap/variables";
@import "../../../../../../tools/node_modules/bootstrap/scss/bootstrap";
@import "../../../bootstrap/bootstrap.scss";

// 4: Core Include
@import "../../core/include";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 5: Demo Configs
@import "header/config";

@import "brand/config";

@import "aside/config";

@import "footer/config";


// 6: Demo Includes
// Layout
@import "base/base";

@import "header/header";
@import "header/base-mobile";
@import "header/menu";
@import "header/topbar";

@import "brand/brand";

@import "aside/aside";

@import "content/content";

@import "footer/footer";

// Custom ScrollBar
.custom-scrollbar {
  padding-right: 15px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba(152, 166, 173, .08);
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(51, 51, 51, .12);
    border-radius: 2px;
  }
}

.scale-hover {
  transition: transform 0.3s ease-out;

  &:hover {
    transform: scale(1.3);
  }
}

.doc-options {
  white-space: nowrap;

  small {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-of-type) {
      width: 30px;
      margin-right: 5px;
    }
  }
}

.docs-list-item {
  align-items: center;
  padding-left: .8rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  border-bottom: 1px solid #dee2e6;

  .kt-link {
    max-width: 100%;
  }
}

// Обращения
.cases-wrapper {
  flex-wrap: nowrap;
  height: calc(100vh - 180px);
}

.cards-search-wrapper {
  margin-bottom: 20px;
  padding-left: 15px;
}

.cards-search {
  position: relative;

  & > .custom-field .custom-input {
    padding-right: 54px;
  }

  & > .btn-link {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 54px;
    height: 54px;
    line-height: 54px;
    color: #a7abc3;
    border: none;
    text-decoration: none;

    &:hover {
      color: kt-brand-color();
    }

    i {
      padding-right: 0;
      font-size: 22px;
      line-height: 46px;
    }
  }

  &-filter {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    display: none;
    margin-top: -1px;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid $input-border-color;
    box-shadow: 0 8px 20px rgba(57, 74, 88, .05);

    &.show {
      display: block;
    }
  }
}

.cards-list {
  position: relative;
  flex-basis: 400px;
  flex-grow: 0;
  width: 400px;
  height: 100%;
  max-width: 100%;
  min-width: 350px;
  min-height: 1px;

  .custom-scrollbar {
    padding-left: 15px;
    padding-right: 10px;
  }

  .card-item {
    display: block;
    margin-bottom: 1rem;
    padding: 1rem;
    color: #4F576C;
    background-color: #fff;
    border-left: 5px solid transparent;
    border-radius: 5px;
    box-shadow: 0 8px 20px rgba(57, 74, 88, .05);
    transition-duration: .3s;

    &:hover {
      box-shadow: 0 8px 20px rgba(57, 74, 88, .15);
    }

    &.active {
      background-color: #fafbfd;
      box-shadow: none;
    }

    &.card-success {
      border-color: $success;
    }

    &.card-warning {
      border-color: $warning;
    }

    &.card-danger {
      border-color: $danger;
    }
  }

  .card-season {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.card-case {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 1rem;
  min-width: 0;

  .card-case-header {
    position: relative;

    & > .dropdown {
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  .notes-and-docs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 100%;
    margin-bottom: 0;

    & > div {
      position: relative;
      flex-basis: 0;
      flex-grow: 1;
      width: 100%;
      max-width: 100%;
      min-height: 1px;
    }

    .service-notes {
      height: 100%;
      padding-right: 10px;

      .chat-conversation {
        height: calc(100% - 31px);
      }

      .chat-bottom {
        padding-right: 30px;
      }
    }

    .service-docs {
      height: 100%;
      padding-left: 10px;

      .hover-btn {
        display: none;
      }

      .docs-list-item {
        padding-left: 0;

        &:hover .hover-btn {
          display: inline-block;
        }
      }
    }
  }
}

.individuals-list {
  flex-basis: 380px;
  width: 380px;
  min-width: 330px;

  .cards-search-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 15px;
  }

  .cards-search {
    flex-grow: 1;
  }

  .btn-add-card {
    width: 54px;
    margin-left: 10px;
    padding: 0;
    line-height: 52px;

    i {
      padding-right: 0;
      font-size: 24px;
    }
  }
}

.permissions-icons {
  display: flex;
  margin-bottom: .5rem;

  svg {
    width: 26px;
    height: 26px;
    fill: #c3c3c3;

    & + svg {
      margin-left: 12px;
    }

    &.active {
      fill: kt-brand-color();
    }
  }
}
